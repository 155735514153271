import { SET_AUDIT_REF } from "../actions"

const INIT_STATE = {
  auditRef: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUDIT_REF:
      return { ...state, auditRef: action.payload }

    default:
      return state
  }
}
