import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { sentryDsn } from "constants/defaultValues"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./store/configureStore"

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"))

ReactDOM.render(
  <Provider store={configureStore().store}>
    <Suspense fallback={<div className="loading" />}>
      <PersistGate loading={null} persistor={configureStore().persistor}>
        <App />
      </PersistGate>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
