import {
  UPDATE_INTERFACE_SETTINGS,
  UPDATE_SEARCH_SETTINGS,
  UPDATE_PAGE_SIZE
} from "../actions"

const INIT_STATE = {
  interfaceSettings: {
    locale: "zh-hant",
    font_size: 1,
    theme: 1,
    time_zone: ""
  },

  searchSettings: [],
  sites: [],
  pageSize: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_INTERFACE_SETTINGS: {
      return {
        ...state,
        interfaceSettings: {
          ...state.interfaceSettings,
          ...action.payload
        }
      }
    }
    case UPDATE_SEARCH_SETTINGS:
      return {
        ...state,
        searchSettings: action.payload
      }

    case UPDATE_PAGE_SIZE:
      return {
        ...state,
        pageSize: {
          ...state.pageSize,
          ...action.payload
        }
      }
    default:
      return state
  }
}
