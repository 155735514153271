/* SETTINGS */
export const UPDATE_CURRENT_MENU_ORG_ID = "UPDATE_CURRENT_MENU_ORG_ID"
export const UPDATE_TOTAL_ORG_COUNT = "UPDATE_TOTAL_ORG_COUNT"
export const UPDATE_TOTAL_MEMBER_COUNT = "UPDATE_TOTAL_MEMBER_COUNT"
export const UPDATE_CURRENT_MENU_ORG_NAME = "UPDATE_CURRENT_MENU_ORG_NAME"
export const UPDATE_CURRENT_MENU_ORG_SEARCH_MODULES =
  "UPDATE_CURRENT_MENU_ORG_SEARCH_MODULES"

export const UPDATE_CURRENT_ORG_SEARCH_SETTINGS =
  "UPDATE_CURRENT_ORG_SEARCH_SETTINGS"
export const CHANGE_LOCALE = "CHANGE_LOCALE"
export const ADD_WATCHLIST = "ADD_WATCHLIST"
export const UPDATE_PIC_PICKER_CALLBACK = "UPDATE_PIC_PICKER_CALLBACK"

/* AUTH */
export const UPDATE_USER_ORG_ID = "UPDATE_USER_ORG_ID"
export const UPDATE_USER_ORG_NAME = "UPDATE_USER_ORG_NAME"
export const UPDATE_TOKEN = "UPDATE_TOKEN"
export const UPDATE_CONTRACT = "UPDATE_CONTRACT"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const LOGOUT = "LOGOUT"
export const UPDATE_AGRESS_TOS_REQUIRED = "UPDATE_AGRESS_TOS_REQUIRED" // 個人資料告知事項暨同意書
export const UPDATE_USER_ORG_INFO = "UPDATE_USER_ORG_INFO"

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES"
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME"
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU"
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES"
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS"

/* DEMOS */
export const UPDATE_SEARCH = "UPDATE_SEARCH"
export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS"

/* SEARCH */
export const UPDATE_SEARCH_ENTRY = "UPDATE_SEARCH_ENTRY"
export const UPDATE_NEGATIVE_NEWS_QUERY = "UPDATE_NEGATIVE_NEWS_QUERY"
export const UPDATE_DEFAULT_PANEL_CONTENT = "UPDATE_DEFAULT_PANEL_CONTENT"
export const TOGGLE_BADGE = "TOGGLE_BADGE"
export const TOGGLE_ALL_BADGES = "TOGGLE_ALL_BADGES"
export const UPDATE_MATCHED_KEYWORDS = "UPDATE_MATCHED_KEYWORDS"
export const UPDATE_INDIVIDUAL_NAMES = "UPDATE_INDIVIDUAL_NAMES"
export const UPDATE_INDIVIDUAL_NAMES_BY_ARRAY =
  "UPDATE_INDIVIDUAL_NAMES_BY_ARRAY"
export const CLEAR_INDIVIDUAL_NAMES = "CLEAR_INDIVIDUAL_NAMES"
export const UPDATE_INDIVIDUAL_PREV_BROWSE_STATE =
  "UPDATE_INDIVIDUAL_PREV_BROWSE_STATE"
export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const UPDATE_DOW_JONES_BROWSE_STATE = "UPDATE_DOW_JONES_BROWSE_STATE"
export const SET_REPORT_REF = "SET_REPORT_REF"
export const UPDATE_RELOAD_STATUS = "UPDATE_RELOAD_STATUS"

/* CUSTOMER_LIST */
export const UPDATE_CUSTOMER_LIST = "UPDATE_CUSTOMER_LIST"
export const REMOVE_NAME_FROM_CUSTOMER_LIST = "REMOVE_NAME_FROM_CUSTOMER_LIST"

/* PREFERENCES */
export const UPDATE_INTERFACE_SETTINGS = "UPDATE_INTERFACE_SETTINGS"
export const UPDATE_SEARCH_SETTINGS = "UPDATE_SEARCH_SETTINGS"
export const UPDATE_PAGE_SIZE = "UPDATE_PAGE_SIZE"

/* AUDIT */
export const SET_AUDIT_REF = "SET_AUDIT_REF"

export * from "./auth/actions"
export * from "./demos/actions"
export * from "./search/actions"
