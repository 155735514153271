import { createStore, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer, createMigrate } from "redux-persist"
import storage from "redux-persist/lib/storage"
import reducers from "./reducers"

const migrations = {
  0: state => {
    return {
      ...state,
      preferences: {
        ...state.preferences,
        pageSize: {}
      }
    }
  },
  1: state => {
    return {
      ...state,
      auth: {
        ...state.auth,
        organization: {}
      }
    }
  }
}

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["customerList", "search", "preferences", "auth", "settings"],
  migrate: createMigrate(migrations, { debug: true })
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middlewares = []

export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  const persistor = persistStore(store)

  // MARK: 要把 redux-persist 整個清空重來，執行下面這行
  // persistor.purge()

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers")
      store.replaceReducer(nextRootReducer)
    })
  }

  return { store, persistor }
}
