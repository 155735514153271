import { UPDATE_TOKEN, UPDATE_CONTRACT } from "../actions"

export const updateToken = token => ({
  type: UPDATE_TOKEN,
  payload: token
})

export const updateContract = contract => ({
  type: UPDATE_CONTRACT,
  payload: contract
})
