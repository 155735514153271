import { combineReducers } from "redux"
import demos from "./demos/reducer"
import settings from "./settings/reducer"
import menu from "./menu/reducer"
import auth from "./auth/reducer"
import customerList from "./customer-list/reducer"
import search from "./search/reducer"
import preferences from "./preferences/reducer"
import audit from "./audit/reducer"

const reducers = combineReducers({
  auth,
  customerList,
  demos,
  menu,
  preferences,
  search,
  settings,
  audit
})

const initialState = reducers({}, {})

export default (state, action) => {
  if (action.type === "LOGOUT") {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }

  return reducers(state, action)
}
