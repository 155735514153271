import { UPDATE_SEARCH, UPDATE_LOADING_STATUS } from "../actions"

const INIT_STATE = {
  search: {
    request_id: "",
    result: {
      number_of_done_articles: 0,
      articles: []
    },
    full_name: "",
    status: 0
  },
  loading: false,
  error: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH:
      // 處理 status:2 人名非50大董監事，尚未建檔
      if (action.payload.status === 2) {
        return { ...state, search: { ...state.search, ...action.payload } }
      }
      return { ...state, search: action.payload }
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error
      }
    default:
      return state
  }
}
