import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import {
  UPDATE_CUSTOMER_LIST,
  REMOVE_NAME_FROM_CUSTOMER_LIST
} from "../actions"

const INIT_STATE = {
  list: []
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_LIST:
      return {
        ...state,
        list: [...new Set([...state.list, action.payload])]
      }
    case REMOVE_NAME_FROM_CUSTOMER_LIST:
      return {
        ...state,
        list: state.list.filter(name => name !== action.payload)
      }
    default:
      return state
  }
}

const persistConfig = {
  key: "customerList",
  storage
}

export default persistReducer(persistConfig, reducer)
