import watchlist from "assets/watchlist"
import {
  UPDATE_CURRENT_ORG_SEARCH_SETTINGS,
  UPDATE_CURRENT_MENU_ORG_ID,
  UPDATE_TOTAL_ORG_COUNT,
  UPDATE_TOTAL_MEMBER_COUNT,
  UPDATE_CURRENT_MENU_ORG_SEARCH_MODULES,
  CHANGE_LOCALE,
  ADD_WATCHLIST,
  UPDATE_PIC_PICKER_CALLBACK
} from "../actions"

const INIT_STATE = {
  // 正在管理的組織（可管理該組織下的員工及搜尋模組
  // This value only for system admin. If login as org admin or employee, this value would always be null.
  currentMenuOrgId: null,
  currentMenuOrgSearchModules: null,
  totalOrgCount: null, // Only super admin have this property
  totalMemberCount: null, // Only organization admin have this property
  onPicPickerChange: () => {},

  currentOrgSearchSettings: null,

  // interface setting
  locale: "zh-hant",
  watchlist
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_ORG_SEARCH_SETTINGS:
      return {
        ...state,
        currentOrgSearchSettings: action.payload
      }

    case UPDATE_CURRENT_MENU_ORG_ID:
      return {
        ...state,
        currentMenuOrgId: action.payload
      }

    case UPDATE_TOTAL_MEMBER_COUNT:
      return {
        ...state,
        totalMemberCount: action.payload
      }

    case UPDATE_TOTAL_ORG_COUNT:
      return {
        ...state,
        totalOrgCount: action.payload
      }

    case UPDATE_CURRENT_MENU_ORG_SEARCH_MODULES:
      return {
        ...state,
        currentMenuOrgSearchModules: action.payload
      }

    case CHANGE_LOCALE:
      return { ...state, locale: action.payload }
    case ADD_WATCHLIST:
      return {
        ...state,
        watchlist: [
          ...state.watchlist,
          { id: state.watchlist.length + 1, ...action.payload }
        ]
      }
    case UPDATE_PIC_PICKER_CALLBACK:
      return { ...state, onPicPickerChange: action.payload }
    default:
      return state
  }
}
