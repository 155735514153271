import Cookies from "universal-cookie"
import {
  UPDATE_USER_ORG_INFO,
  UPDATE_USER_ORG_ID,
  UPDATE_USER_ORG_NAME,
  UPDATE_TOKEN,
  UPDATE_AGRESS_TOS_REQUIRED,
  UPDATE_CURRENT_USER,
  UPDATE_CONTRACT,
  LOGOUT
} from "../actions"

const INIT_STATE = {
  token: "",
  agressTosRequired: null,
  userOrgId: "",
  currentUser: {
    // account: "",
    // email_address: "@mail.com",
    // employee_code: "",
    // enable: true,
    // first_name: "",
    // id: 123,
    // last_name: "",
    // phone_number: "",
    // role: 1,
    // search_setting_id: 1,
    // ui: { theme: 1, font_size: 2, language: "zh-TW" }
  },
  contract: {},
  organization: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_ORG_INFO:
      return { ...state, organization: action.payload }
    case UPDATE_USER_ORG_ID: {
      const cookies = new Cookies()
      cookies.set("userOrgId", action.payload, { path: "/" })

      return {
        ...state,
        userOrgId: action.payload
      }
    }
    case UPDATE_USER_ORG_NAME:
      return { ...state, userOrgName: action.payload }
    case UPDATE_TOKEN: {
      // MARK: side effect to cookie
      const cookies = new Cookies()
      cookies.set("token", action.payload, { path: "/" })

      return { ...state, token: action.payload }
    }
    case UPDATE_AGRESS_TOS_REQUIRED: {
      return { ...state, agressTosRequired: action.payload }
    }
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload }
      }
    case UPDATE_CONTRACT:
      return {
        ...state,
        contract: action.payload
      }

    case LOGOUT: {
      // MARK: side effect to cookie and work-around for iframe
      const cookies = new Cookies()
      cookies.remove("token")

      // setTimeout(() => {
      //   window.location.reload()
      // }, 200)

      return { ...INIT_STATE, userOrgId: cookies.get("userOrgId") }
    }

    default:
      return state
  }
}
