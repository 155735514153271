import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive
} from "./constants/defaultValues"
import { getCurrentColor, setCurrentColor } from "./helpers/Utils"

import "./assets/css/vendor/bootstrap.min.css"
import "./assets/css/vendor/bootstrap.rtl.only.min.css"
import "rc-switch/assets/index.css"
import "react-circular-progressbar/dist/styles.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import "rc-slider/assets/index.css"
import "filepond/dist/filepond.min.css"
import "react-toastify/dist/ReactToastify.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css"
import "./assets/css/style.css"

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor
setCurrentColor(color)

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require("./AppRenderer")
  })
}

render()
