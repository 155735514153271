import {
  UPDATE_SEARCH_ENTRY,
  UPDATE_NEGATIVE_NEWS_QUERY,
  UPDATE_DEFAULT_PANEL_CONTENT,
  TOGGLE_BADGE,
  TOGGLE_ALL_BADGES,
  UPDATE_MATCHED_KEYWORDS,
  UPDATE_INDIVIDUAL_NAMES,
  UPDATE_INDIVIDUAL_NAMES_BY_ARRAY,
  CLEAR_INDIVIDUAL_NAMES,
  UPDATE_INDIVIDUAL_PREV_BROWSE_STATE,
  UPDATE_ADDRESS,
  UPDATE_DOW_JONES_BROWSE_STATE,
  SET_REPORT_REF,
  UPDATE_RELOAD_STATUS
} from "../actions"

const INIT_STATE = {
  searchEntry: "individual",
  isAllNegativeKeywordsSelected: true,
  keywords: [],
  sources: [],
  matchedKeywords: [],
  individual: {
    names: [],
    prevBrowseState: {
      tabId: 1,
      fileCollapsed: false,
      url: "",
      dowJones: {}
    }
  },
  address: "",
  reportRef: null,
  reloadStatus: true
}

const mapToggleValue = (state, action) => {
  switch (action.payload.sectionType) {
    case "keywords":
      return {
        ...state,
        keywords: state.keywords.map(keyword => {
          if (keyword.id !== action.payload.id) {
            return keyword
          }

          return { ...keyword, value: !keyword.value }
        })
      }
    case "sources":
      return {
        ...state,
        sources: state.sources.map(source => {
          if (source.id !== action.payload.id) {
            return source
          }

          return { ...source, value: !source.value }
        })
      }
    default:
      return state
  }
}

const mapAllToggleValues = (state, action) => {
  switch (action.payload.sectionType) {
    case "keywords":
      return {
        ...state,
        keywords: state.keywords.map(keyword => ({
          ...keyword,
          value: action.payload.value
        }))
      }
    case "sources":
      return {
        ...state,
        sources: state.sources.map(source => ({
          ...source,
          value: action.payload.value
        }))
      }
    default:
      return state
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_ENTRY:
      return { ...state, searchEntry: action.payload }
    case UPDATE_NEGATIVE_NEWS_QUERY:
      return { ...state, ...action.payload }
    case UPDATE_DEFAULT_PANEL_CONTENT:
      return {
        ...state,
        keywords: action.payload.keywords,
        sources: action.payload.sources
      }
    case TOGGLE_BADGE:
      return mapToggleValue(state, action)
    case TOGGLE_ALL_BADGES:
      return mapAllToggleValues(state, action)
    case UPDATE_MATCHED_KEYWORDS:
      // eslint-disable-next-line no-case-declarations
      const preparedMatchedKeywords = state.matchedKeywords
      if (action.payload.length !== 0) {
        action.payload.map(matchedword => {
          if (!preparedMatchedKeywords.includes(matchedword)) {
            preparedMatchedKeywords.push(matchedword)
          }

          return preparedMatchedKeywords
        })
      }

      return {
        ...state,
        matchedKeywords: preparedMatchedKeywords
      }
    case UPDATE_INDIVIDUAL_NAMES: {
      const { individual } = state

      return {
        ...state,
        individual: {
          ...individual,
          names: [...new Set([...individual.names, action.payload])]
        }
      }
    }
    case UPDATE_INDIVIDUAL_NAMES_BY_ARRAY: {
      const { individual } = state

      return {
        ...state,
        individual: {
          ...individual,
          names: [...new Set([...individual.names, ...action.payload])]
        }
      }
    }
    case CLEAR_INDIVIDUAL_NAMES: {
      const { individual } = state

      if (!action.payload) {
        return { ...state, individual: { ...individual, names: [] } }
      }

      return {
        ...state,
        individual: {
          ...individual,
          names: [...individual.names.filter(name => name !== action.payload)]
        }
      }
    }
    case UPDATE_INDIVIDUAL_PREV_BROWSE_STATE:
      return {
        ...state,
        individual: {
          ...state.individual,
          prevBrowseState: {
            ...state.individual.prevBrowseState,
            ...action.payload
          }
        }
      }
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.payload
      }
    case UPDATE_DOW_JONES_BROWSE_STATE:
      return {
        ...state,
        individual: {
          ...state.individual,
          prevBrowseState: {
            ...state.individual.prevBrowseState,
            dowJones: {
              ...state.individual.prevBrowseState.dowJones,
              ...action.payload
            }
          }
        }
      }
    case SET_REPORT_REF:
      return { ...state, reportRef: action.payload }
    case UPDATE_RELOAD_STATUS:
      return { ...state, reloadStatus: action.payload }
    default:
      return state
  }
}
