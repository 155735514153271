const data = [
  {
    id: 1,
    description:
      "Australia Autonomous Sanctions for Russia Crimea and Sevastopol",
    country: "Australia"
  },
  {
    id: 2,
    description: "Australian National Security Terrorism List",
    country: "Australia"
  },
  {
    id: 3,
    description: "DFAT Australia Consolidated Sanctions List",
    country: "Australia"
  },
  {
    id: 4,
    description:
      "Austria Oesterreichische Nationalbank OENB Historical Sanctions",
    country: "Austria"
  },
  {
    id: 5,
    description: "Austria Oesterreichische Nationalbank OENB Sanctions",
    country: "Austria"
  },
  {
    id: 6,
    description:
      "Belgium Federal Public Service Finance National Financial Sanctions",
    country: "Belgium"
  },
  {
    id: 7,
    description:
      "Bulgaria Domestic Sanctions under the Council of Ministers Decision 265 of 2003",
    country: "Bulgaria"
  },
  { id: 8, description: "Canada OSFI Entity List", country: "Canada" },
  { id: 9, description: "Canada OSFI Person List", country: "Canada" },
  {
    id: 10,
    description: "Canadian Freezing Assets of Corrupt Foreign Officials Act",
    country: "Canada"
  },
  {
    id: 11,
    description:
      "Canadian Sanctions Justice for Victims of Corrupt Foreign Officials Act",
    country: "Canada"
  },
  {
    id: 12,
    description: "DFATD Canada Special Economic Measures Act Designations",
    country: "Canada"
  },
  {
    id: 13,
    description: "Public Safety Canada Listed Entities",
    country: "Canada"
  },
  {
    id: 14,
    description: "China Ministry of Public Security Terrorist list",
    country: "China"
  },
  {
    id: 15,
    description: "France Tresor Direction Generale Liste Unique de Gels",
    country: "France"
  },
  {
    id: 16,
    description: "India Ministry of Home Affairs Banned Organisations",
    country: "India"
  },
  {
    id: 17,
    description:
      "Indonesia PPATK List of Funders of Proliferation of WMD Sanctions",
    country: "Indonesia"
  },
  {
    id: 18,
    description:
      "Indonesia PPATK List of Suspected Terrorists and Terrorist Organizations DTTOT Sanctions",
    country: "Indonesia"
  },
  {
    id: 19,
    description: "Arab states Sanctions List against Qatar related individuals",
    country: "International"
  },
  {
    id: 20,
    description:
      "Arab states Sanctions List against Qatar related organisations",
    country: "International"
  },
  {
    id: 21,
    description: "EU External Action Service - Consolidated list of Sanctions",
    country: "International"
  },
  {
    id: 22,
    description: "European Council List of Designated Vessels",
    country: "International"
  },
  {
    id: 23,
    description:
      "European Council Restrictive Measures on Russian entities destabilising the situation in Ukraine",
    country: "International"
  },
  {
    id: 24,
    description: "Sanction Related Entities",
    country: "International"
  },
  { id: 25, description: "UN Consolidated", country: "International" },
  {
    id: 26,
    description: "UN Consolidated - Related Entities",
    country: "International"
  },
  { id: 27, description: "UN Liberia Sanctions", country: "International" },
  {
    id: 28,
    description: "UN Security Council List of Designated Vessels",
    country: "International"
  },
  {
    id: 29,
    description: "Iran March and May 2017 Sanctions against US entities",
    country: "Iran"
  },
  {
    id: 30,
    description: "Ireland Organisations Sanction Laws",
    country: "Ireland"
  },
  { id: 31, description: "Israel Ministry of Defence", country: "Israel" },
  {
    id: 32,
    description: "Japan METI Export Control Security Division End User list",
    country: "Japan"
  },
  {
    id: 33,
    description: "Ministry of Finance Japan Economic sanctions list",
    country: "Japan"
  },
  {
    id: 34,
    description:
      "Kenya Gazette Notices of Sanctions Designations under the Prevention of Terrorism Act",
    country: "Kenya"
  },
  { id: 35, description: "Latvia National Sanctions List", country: "Latvia" },
  {
    id: 36,
    description: "Luxembourg Liste des Sanctions Financieres Internationales",
    country: "Luxembourg"
  },
  {
    id: 37,
    description:
      "Malaysia Ministry of Home Affairs List of Sanctioned Entities",
    country: "Malaysia"
  },
  {
    id: 38,
    description:
      "Malaysia Ministry of Home Affairs List of Sanctioned Individuals",
    country: "Malaysia"
  },
  {
    id: 39,
    description: "Malta Financial Services Authority (MFSA) National Sanctions",
    country: "Malta"
  },
  { id: 40, description: "Monaco Ministerial Orders", country: "Monaco" },
  {
    id: 41,
    description: "Sanction Netherlands Domestic Terrorist List",
    country: "Netherlands"
  },
  {
    id: 42,
    description: "New Zealand Police Designated Terrorists New",
    country: "Zealand"
  },
  {
    id: 43,
    description: "Norway Lovdata laws judicial database",
    country: "Norway"
  },
  {
    id: 44,
    description: "Philippines AMLC Sanctions Resolutions",
    country: "Philippines"
  },
  {
    id: 45,
    description: "Qatar National Terrorist Designation Lists",
    country: "Qatar"
  },
  {
    id: 46,
    description: "Russian ROSFIN Monitoring List Extremism and Terrorism",
    country: "Russia"
  },
  {
    id: 47,
    description: "Russian ROSFIN Monitoring WMD List",
    country: "Russia"
  },
  {
    id: 48,
    description:
      "Russian Sanctions Federal Security Service List of Terrorist Organisations",
    country: "Russia"
  },
  {
    id: 49,
    description:
      "Serbia Government Decisions on Terrorism related Sanctions Designations",
    country: "Serbia"
  },
  {
    id: 50,
    description:
      "Singapore First Schedule of The Terrorism (Suppression of Financing Act)",
    country: "Singapore"
  },
  {
    id: 51,
    description:
      "Singapore First Schedule of the Terrorism (Suppresion of Financing) Act 2",
    country: "Singapore"
  },
  {
    id: 52,
    description:
      "Singapore MAS List * covered through the Singapore First Schedule of the Terrorism (Suppression of Financing) Act list and the UN Consolidated list (that include UN Resolutions 1718, 1533, 2231, 1970, 1844, 2206, 1591, 2140, 1267/1989, 1988 Taliban List, that MAS Singapore transfers under national jurisdiction)",
    country: "Singapore"
  },
  {
    id: 53,
    description:
      "South Korea Ministry of Strategy and Finance Sanctions on Iran and North Korea ",
    country: "South Korea"
  },
  {
    id: 54,
    description:
      "Sri Lanka Ministry of Defence National Sanctions Designations under UN Regulations concerning terrorism ",
    country: "Sri Lanka"
  },
  {
    id: 55,
    description:
      "Sri Lanka Ministry of Defence Sanctions Designations under UN Regulation concerning the proliferation of WMD ",
    country: "Sri Lanka"
  },
  {
    id: 56,
    description: "Swiss Federal Council Freezing of Foreign Illicit Assets",
    country: "Switzerland"
  },
  { id: 57, description: "Swiss SECO List", country: "Switzerland" },
  {
    id: 58,
    description: "TH-Thailand designated persons list",
    country: "Thailand"
  },
  {
    id: 59,
    description: "Thailand Designated Persons List",
    country: "Thailand"
  },
  {
    id: 60,
    description: "Ukraine State Financial Monitoring Black List",
    country: "Ukraine"
  },
  {
    id: 61,
    description:
      "Ukraine Sanctions National Security and Defense Council (NSDC) Special Economic and Other Restrictive  Measures  - Organisations",
    country: "Ukraine"
  },
  {
    id: 62,
    description:
      "Ukraine Sanctions National Security and Defense Council (NSDC) Special Economic and Other Restrictive Measures - Persons",
    country: "Ukraine"
  },
  {
    id: 63,
    description: "UAE List of Designated Terrorist Organisations and Groups ",
    country: "United Arab Emirates"
  },
  {
    id: 64,
    description:
      "HM Treasury Ukraine Sanctions * distinct from UK HM Treasury Office of Financial Sanctions Implementation Consolidated List ",
    country: "United Kingdom"
  },
  {
    id: 65,
    description:
      "UK HM Treasury Office of Financial Sanctions Implementation Consolidated List * formerly called HM Treasury List ",
    country: "United Kingdom"
  },
  {
    id: 66,
    description: "UK Home Office List of Proscribed Terrorist Organisations ",
    country: "United Kingdom"
  },
  { id: 67, description: "OFAC Consolidated List ", country: "United States" },
  { id: 68, description: "OFAC SDN List ", country: "United States" },
  {
    id: 69,
    description: "OFAC SDN List - Related Entities ",
    country: "United States"
  },
  {
    id: 70,
    description: "United States Coast Guard Prohibited Vessels ",
    country: "United States"
  },
  {
    id: 71,
    description: "US Department of State Cuban Restricted Entities List ",
    country: "United States"
  },
  {
    id: 72,
    description: "US Department of State Nonproliferation Sanctions List ",
    country: "United States"
  },
  {
    id: 73,
    description: "US Department of State Terror Exclusion List ",
    country: "United States"
  },
  {
    id: 74,
    description: "Vietnam Sanctions Ministry of Public Security Terrorism List",
    country: "Vietnam"
  },
  {
    id: 75,
    description:
      "Australia Autonomous Sanctions North Korea Designated Vessels",
    country: "Australia"
  },
  {
    id: 76,
    description:
      "Azerbaijan Financial Intelligence Unit National Sanctions List",
    country: "Azerbaijan"
  },
  {
    id: 77,
    description:
      "Belarus State Security Agency List of Organizations and Individuals Involved in Terrorist Activities",
    country: "Belarus"
  },
  {
    id: 78,
    description: "Israel Ministry of Finance Sanctions List",
    country: "Israel"
  },
  {
    id: 79,
    description:
      "Kyrgyzstan State Financial Intelligence Service National List of Sanctions",
    country: "Kyrgyzstan"
  },
  {
    id: 80,
    description:
      "Kazakhstan Committee on Legal Statistics and Special Records of the Prosecutor General List of Terrorist and Extremist Organizations",
    country: "Kazakhstan"
  },
  {
    id: 81,
    description: "Russia Government Special Economic Measures on Ukraine",
    country: "Russia"
  },
  {
    id: 82,
    description:
      "Belgium Consolidated List of the National and European Sanctions",
    country: "Belgium"
  },
  {
    id: 83,
    description:
      "Argentina Ministerio de Relaciones Exteriores y Culto Sanciones de la ONU",
    country: "Argentina"
  },
  {
    id: 84,
    description:
      "Pakistan National Counter Terrorism Authority List of Organizations Proscribed by the Ministry of Interior",
    country: "Pakistan"
  },
  {
    id: 85,
    description:
      "Tunisia Sanctions National list of persons, organizations and entities associated with terrorist offenses",
    country: "Tunisia"
  },
  {
    id: 86,
    description:
      "Czech Republic Sanctions Government Order to implement specific international measures to combat terrorism ",
    country: "Czech Republic"
  },
  {
    id: 87,
    description: "Romania Terrorism Related Sanctions List",
    country: "Romania"
  },
  {
    id: 88,
    description: "Norway Sanctions and Restrictive Measures",
    country: "Norway"
  },
  {
    id: 89,
    description: "Bangladesh Domestic Sanctions List",
    country: "Bangladesh"
  },
  {
    id: 90,
    description:
      "Canada Regulations Implementing the United Nations Resolutions on the Suppression of Terrorism",
    country: "Canada"
  },
  {
    id: 91,
    description:
      "Turkey Financial Crimes Investigation Board Terrorism Asset Freezes",
    country: "Turkey"
  },
  {
    id: 92,
    description:
      "South Korea Financial Services Commission Transaction Ban Designations ",
    country: "South Korea"
  },
  {
    id: 93,
    description:
      "South Korea Ministry of Economy and Finance Transaction Ban Designations ",
    country: "South Korea"
  },
  {
    id: 94,
    description: "Russia Central Bank Special Economic Measures Designations",
    country: "Russia"
  },
  {
    id: 95,
    description: "United Kingdom Jersey Proscribed Terrorist Organizations ",
    country: "United Kingdom"
  },
  {
    id: 96,
    description: "South Africa Targeted Financial Sanctions List persons ",
    country: "South Africa"
  },
  {
    id: 97,
    description:
      "South Africa Targeted Financial Sanctions List organisations ",
    country: "South Africa"
  },
  {
    id: 98,
    description: "Argentina National Securities Commission Warning Notes",
    country: "Argentina"
  },
  {
    id: 99,
    description: "BR-National Register of Punished Companies CNEP",
    country: "Brazil"
  },
  {
    id: 100,
    description: "BR-Register of Non-Profit Entities CEPIM",
    country: "Brazil"
  }
]

export default data
