import { UserRole } from "../helpers/authHelper"
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default"

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = "zh-hant"
export const localeOptions = [
  { id: "zh-hant", name: "繁體中文", direction: "ltr" },
  { id: "en", name: "English", direction: "ltr" }
]
export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin
}

// configured by dotenv file
export const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT || "https://api-dev.kryptogo.com"
export const adminRoot = "/compliance"
export const buyUrl = "https://1.envato.market/k4z0"
export const searchPath = `${adminRoot}/#`
export const servicePath = `${apiEndpoint}/dd`
export const pocServicePath = "https://api-allianz.kryptogo.dev"
export const authServicePath = `${apiEndpoint}/auth`
export const companyInfoServicePath =
  "https://proxy.kryptogo.dev/www.twincn.com"
export const aiShowcaseServicePath = "https://9e3cccfd4b9e.ngrok.io"
export const sentryDsn =
  process.env.REACT_APP_SENTRY ||
  "https://a9f259e908e24afebe82dabcbae77b88@o575208.ingest.sentry.io/5727115"
export const reCaptchaKey =
  process.env.REACT_APP_RECAPTCHAKEY ||
  "6LdMjSgdAAAAAJgaJC0Q-PSc2kqC_rqR6yLBWLBS"
export const twVerdictUrl =
  "https://judgment.judicial.gov.tw/FJUD/data.aspx?ty=JD&id="

export const themeColorStorageKey = "__theme_selected_color"
export const isMultiColorActive = true
export const defaultColor = "light.bluenavy"
export const isDarkSwitchActive = true
export const defaultDirection = "ltr"
export const themeRadiusStorageKey = "__theme_radius"
export const isAuthGuardActive = false
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel"
]
export const megaAccountsLimitNumber = 26
